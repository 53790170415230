import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div>
      the quick brown fox jumps over the lazy dog. 1
    </div>
  );
}

export default App;
